:root {
    --sky: #05BDF0;
    --orange: #F25626;
    --yellow: #FFBA18;
    --green: #69BF61;
    --navy: #244371;
    --blue: #0166f7;
}

body {
    margin: 0;
    font-size: 17px;
}

body p {
    line-height: 1.75;
}

a {
    text-decoration: none;
}

/* Header Area CSS - Starts */

.logo img {
    width: 320px;
}

.header-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}

.landing-body {
    padding-top: 110px;
}

.piearm-section {
    padding: 50px 0;
}

@media (max-width: 767px) {


    .landing-body {
        padding-top: 150px;
    }
}

.container {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding-left: 50px;
    padding-right: 50px;
    box-sizing: border-box;
}

.main-menu {
    display: flex;
    gap: 20px;
    align-items: center;
}

.main-menu a {
    color: #444;
}

.menu-item-button {
    background-color: var(--blue);
    color: #fff !important;
    display: inline-block;
    padding: 10px 30px;
    border-radius: 50px;
}

.menu-item-button:hover {
    opacity: .85 !important;
}

header {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 99;
}

/* Header Area CSS - Ends */

/* Footer Area CSS - Starts */

footer {
    background-color: #000;
}

span.copyright {
    color: #fff;
    font-size: 15px;
}

.footer-container {
    padding: 15px 50px;
    text-align: center;
}

/* Header Area CSS - Ends */

.hero-heading h1 {
    margin: 0;
    font-size: 54px;
}

.price-heading{
    background: -webkit-linear-gradient(360deg, var(--green), var(--sky));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 45px;
}

.price-button a {
    background-color: var(--blue);
    padding: 18px 25px;
    border-radius: 5px;
    transition: .5s;
    font-size: 17px !important;
    display: block;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
    border: 1px solid var(--blue) !important;
}

.price-button-paid a {
    background-color: var(--blue);
    color: #fff;
}

.price-button-free a {
    background-color: transparent;
    color: var(--blue);
}

.price-button-free a:hover {
    background-color: #ecfaff;
}

.price-button-paid a:hover {
    background-color: var(--sky);
    border-color: var(--sky) !important;
}

.section-title {
    margin-top: 0;
    text-align: center;
    font-size: 30px;
    line-height: 1.5;
    margin-bottom: 10px;
}

.inner-three-column {
    display: grid;
    gap: 40px;
    grid-template-columns: 1fr;
}

.heading-wrap {
    max-width: 100%;
    margin: 0 auto 60px;
}

.primary-subhead {
    text-align: center;
    line-height: 1.8;
    color: #555;
    font-size: 19px;
}

.primary-subhead-dark {
    text-align: center;
    line-height: 1.8;
    color: #fafafa;
    font-size: 19px;
}

.pricing-column {
    border: 1px solid #eaeaea !important;
    padding: 25px;
    border-radius: 10px;
    box-shadow: 0 0 6px 3px #efefefc2;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 40px;
    background-color: #fafafa;
}

.pricing-column-dark {
    border: 1px solid #eaeaea !important;
    padding: 25px;
    border-radius: 10px;
    box-shadow: 0 0 6px 3px #efefefc2;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 40px;
    background-color: #151920;
}

.price-heading {
    text-align: center;
    font-weight: bold;
    font-size: 27px;
}

.price-ammount {
    text-align: center;
    padding: 30px 0 40px;
}

.price-figure {
    font-size: 40px;
    font-weight: bold;
}

.popular-item span {
    text-align: center;
    display: inline-block;
    padding: 8px 15px;
    border-radius: 50px;
    font-size: 15px;
    background-color: #fff;
    border: 1px solid var(--green);
}

.popular-item-dark span {
    text-align: center;
    display: inline-block;
    padding: 8px 15px;
    border-radius: 50px;
    font-size: 15px;
    background-color: #151920;
    border: 1px solid var(--green);
}

.popular-item {
    text-align: center;
    position: absolute;
    left: calc(50% - 65px);
    top: -16px;
}

.popular-item-dark{
    text-align: center;
    position: absolute;
    left: calc(50% - 65px);
    top: -16px;
}

.price-features ul {
    padding-left: 20px;
    margin: 0;
}

.price-features ul li:nth-child(n+2) {
    margin-top: 15px;
}

.price-features li {
    line-height: 1.6;
}

@media (min-width: 768px) {
    .section-title {
        font-size: 45px;
    }

    .pricing-column {
        padding: 60px 40px;
    }

    .price-heading {
        font-size: 35px;
    }

    .price-figure {
        font-size: 55px;
    }

    .inner-three-column {
        grid-template-columns: 1fr 1fr;
    }
}

@media (min-width: 1051px) {
    .inner-three-column {
        grid-template-columns: 1fr 1fr 1fr;
    }
}