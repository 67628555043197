:root {
    --sky: #05BDF0;
    --orange: #F25626;
    --yellow: #FFBA18;
    --green: #69BF61;
    --navy: #244371;
    --blue: #0166f7;
}

body {
    margin: 0;
    font-size: 17px;
}

body p {
    line-height: 1.75;
}

a {
    text-decoration: none;
}

.logo img {
    width: 320px;
}

.header-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.container {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding-left: 50px;
    padding-right: 50px;
    box-sizing: border-box;
}

.main-menu {
    display: flex;
    gap: 20px;
    align-items: center;
}

.main-menu a {
    color: #444;
}

.menu-item-button {
    background-color: var(--blue);
    color: #fff !important;
    display: inline-block;
    padding: 10px 30px;
    border-radius: 50px;
}

.menu-item-button:hover {
    opacity: .85 !important;
}

header {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 99;
}

.body-container {
    display: grid;
    gap: 100px;
    z-index: 9;
    position: relative;
    padding: 160px 50px 50px 50px;
    grid-template-columns: 1fr !important;
    align-items: center;
    max-width: 1400px;
    margin: 0 auto;
}

.image-shadow,
.main-image,
.hero-image-wrap img {
    border-radius: 10px;
    box-shadow: 0 0 5px 3px rgb(0 0 0 / 10%);
    width: unset;
}

.secondary-image {
    position: absolute;
    bottom: 60px;
    left: 0;
}

.hero-image-wrap {
    position: relative;
    padding-left: 100px;
}

@media (min-width: 768px) {
    .desktop-hide {
        display: none;
    }
}

@media (max-width: 767px) {
    .hero-image-wrap {
        display: none;
    }
}

.main-image {
    width: auto;
    height: auto;
    min-height: 400px;
    background-size: cover;
    background-position: top left;
}

#hero_top {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    position: relative;
}

#hero_top:before {
    content: '';
    position: absolute;
    inset: 0;
    background-color: rgb(255 255 255 / 65%);
    z-index: 1;
}

.hero-right.hero-column {
    text-align: end;
}

.main-image img {
    width: auto;
    height: auto;
    max-width: 100%;
}

footer {
    background-color: #000;
}

span.copyright {
    color: #fff;
    font-size: 15px;
}

.footer-container {
    padding: 15px 50px;
    text-align: center;
}

.hero-heading h1 {
    margin: 0;
    font-size: 54px;
}

.hero-heading h2 {
    font-size: 34px;
    margin-bottom: 6px;
}

.hero-heading h3 {
    font-size: 52px;
    margin-bottom: 6px;
}

@media (max-width: 800px) {
    .hero-heading h2 {
      font-size: 24px;
    }
  
    .hero-heading h3 {
      font-size: 36px;
    }
}

.hero-subhead {
    font-weight: bold !important;
    font-size: 30px;
    margin: 30px 0;
    margin-top: 0;
    display: flex;
    align-items: end;
    gap: 15px;
    flex-wrap: wrap;
    line-height: 1;
}

span.gradient-item {
    background: -webkit-linear-gradient(360deg, var(--green), var(--sky));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 45px;
}

.hero-content {
    margin-bottom: 25px;
    line-height: 1.8;
}

.regular-button a {
    background-color: var(--blue);
    color: #fff;
    display: inline-block;
    padding: 15px 25px;
    border-radius: 5px;
    transition: .5s;
    font-size: 16px !important;
    box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
    letter-spacing: 0.5px;
}

.regular-button a:hover {
    background-color: var(--sky);
}

#process .container {
    padding-top: 65px;
    padding-bottom: 65px;
}

h2.section-title {
    margin-top: 0;
    text-align: center;
    font-size: 30px;
    line-height: 1.5;
    margin-bottom: 80px;
}

.inner-three-column {
    display: grid;
    gap: 40px;
    margin-bottom: 50px;
    grid-template-columns: 1fr;
}

@media (min-width: 768px) {
    h2.section-title {
        font-size: 45px;
    }
}

@media (min-width: 900px) {
    .inner-three-column {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

.icon-column {
    /* text-align: center; */
    border: 1px solid #e9e9e9 !important;
    padding: 60px 40px 30px;
    border-radius: 10px;
    box-shadow: 0 0 7px 3px hwb(0deg 94% 6% / 50%) !important;
    transition: .5s;
    position: relative;
}

.icon-column-dark {
    /* text-align: center; */
    border: 1px solid #c4c4c4 !important;
    padding: 60px 40px 30px;
    border-radius: 10px;
    box-shadow: 0 0 7px 3px hwb(0deg 94% 6% / 50%) !important;
    transition: .5s;
    position: relative;
    background-color: #202436;
}

.step-icon svg {
    width: auto;
    height: 90px;
}

.step-icon svg {
    fill: var(--blue) !important;
}

.step-icon svg path {
    stroke: var(--blue) !important;
}

.icon-column:hover {
    transform: translateY(-5px);
    background: #f9f9f9;
}

.step-item {
    position: absolute;
    top: 0;
    background-color: #222;
    color: #fff;
    width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    border-radius: 100%;
    top: -28px;
    /* left: calc(50% - 28px); */
}

.step-item-dark {
    position: absolute;
    top: 0;
    background-color: #383f57;
    color: #fff;
    width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    border-radius: 100%;
    top: -28px;
    /* left: calc(50% - 28px); */
}

.center-button {
    text-align: center;
}

h3.icon-column-title {
    color: #666;
    font-size: 23px;
    line-height: 1.4;
    margin: 20px 0 15px;
}

h3.icon-column-title-dark {
    color: #72a1c4;
    font-size: 23px;
    line-height: 1.4;
    margin: 20px 0 15px;
}

img.image-shadow {
    width: 100%;
    height: auto;
}

@media (min-width: 990px) {
    .hero-left.hero-column {
        padding-bottom: 100px;
    }

    .body-container {
        grid-template-columns: 1fr 1fr !important;
    }

    .main-image {
        min-height: 550px;
    }
}

@media (max-width: 767px) {
    .body-container,
    .header-container {
        flex-wrap: wrap;
    }


    .body-container {
        gap: 30px;
        padding-top: 60px !important;
    }

    .hero-column {
        width: 100% !important;
        text-align: center;
    }

    .hero-heading h1 {
        font-size: 40px;
    }

    .hero-subhead {
        font-size: 25px;
        justify-content: center;
    }

    span.gradient-item {
        font-size: 40px;
    }
}

@media (max-width: 989px) {
    .hero-left.hero-column {
        order: 2;
    }
}

.custom-quill-editor .ql-container {
    background-color: #f0f0f0;
}

.ql-container {
    border-radius: 0px 0px 8px 8px; /* Rounded corners */
    border: 1px solid #ddd; /* Customize border */
    background-color: #f9f9f9; /* Background color */
  }
  
  .ql-toolbar {
    border-radius: 8px 8px 0 0; /* Toolbar with top rounded corners */
    background-color: #f1f1f1; /* Toolbar background color */
    border: 1px solid #ddd;
  }
  
  .ql-editor {
    color: #333; /* Text color inside the editor */
  }
  